header img {
    width: 40px;
    height: 40px;
}

header .cat {
    width: 60px;
    height: 60px;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

header .right {
    display: flex;
    align-items: center;
    gap: 10px;
}